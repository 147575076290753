<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <v-toolbar flat>
          <v-icon class="mr-4">mdi-bullhorn-outline</v-icon>
          <v-toolbar-title>Promotions</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="create()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row dense class="ma-2">
          <v-col class="px-2">
            <v-text-field
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              @input="query"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-data-table
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          :headers="headers"
          :items="data"
          :key="promotionListKey"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @click:row="view"
          @update:options="paginate"
        >
          <template v-slot:item.startDate="{ item }">
            {{ item.startDate | formatDate }}
          </template>
          <template v-slot:item.endDate="{ item }">
            {{ item.endDate | formatDate }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      width="650px"
      @keydown.esc="dialog = false"
    >
      <promotion-form
        :model="editItem"
        :key="key"
        @create="handleCreate"
        @update="handleUpdate"
        @close="reset"
      ></promotion-form>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseList from '../../../helpers/base-list';
import PromotionForm from './PromotionForm.vue';

export default {
  name: 'PromotionList',

  extends: BaseList,

  components: {
    PromotionForm,
  },

  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Code', value: 'code' },
      { text: 'Start', value: 'startDate' },
      { text: 'End', value: 'endDate' },
    ],
    promotionListKey: 1,
  }),

  created() {
    this.url = 'promotions';
  },

  methods: {
    searchQuery(term) {
      return `name like ${term}`;
    },

    view(item) {
      this.$router.push({ name: 'promotion-view', params: { id: item.id } });
    },
  },
};
</script>
